/** @jsxImportSource @emotion/react  */
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { useLocation } from "react-router-dom";

import { css } from "@emotion/react";
import axios from "axios";
import crypto from "crypto";

import logo from "./nomasse-w-square.svg";

const Eka = () => {
  const location = useLocation();
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [text, setText] = useState("0");
  const [id, setId] = useState<{
    form_id: string;
    user_id: string;
    eka_data: string;
  }>(location.state as { form_id: string; user_id: string; eka_data: string });
  const max_drink_num = process.env.REACT_APP_MAX_DRINK_NUM || "";

  // curl -X POST -H "Content-Type: application/json" -d '{"tag_id": "0001"}' https://31ruz4oapc.execute-api.ap-northeast-1.amazonaws.com/test_generate/get/
  const url =
    "https://mzskclw0m6.execute-api.ap-northeast-1.amazonaws.com/dev/get/";
  //const url =
  //  "https://31ruz4oapc.execute-api.ap-northeast-1.amazonaws.com/test_generate/get/";

  axios.defaults.headers.post["Content-Type"] =
    "application/json;charset=utf-8";

  useEffect(() => {
    console.log("fetch" + JSON.stringify(id));
    // アンケートIDがある場合は、アンケートIDを元にQRを取得する
    let user_id = id.form_id + "-" + id.user_id;
    let eka_data = {
      tag_id: "error_tag",
      group: "error_grp",
    };
    if (id.eka_data) {
      let eka_body = JSON.parse(id.eka_data);
      eka_data = {
        tag_id: eka_body.id,
        group: eka_body.gp,
      };
    }
    const currentHour = new Date();
    console.log("tag_id: " + JSON.stringify(eka_data));
    /*const data = {
      tag_id: id.eka_id,
      group: id.eka_id,
    };*/

    axios
      .post(url, eka_data)
      .then((response) => {
        setImgSrc("data:image/svg+xml;base64," + response.data.body);
      })
      .catch((err) => {
        console.log(err);
      });
    //    }, [data]);
  }, []);

  useEffect(() => {
    //API Gatewayと接続する
    //WebSocket 処理
    const socketURL =
      process.env.REACT_APP_WEBSOCKET_URL ||
      "wss://www.example.com/socketserver";
    let socketidUrl =
      socketURL + "/?form_id=" + id.form_id + "&user_id=" + id.user_id;
    let initialConnect = true;

    let socket = new WebSocket(socketidUrl);
    socket.onopen = (event) => {
      console.log("Connect Successed!" + JSON.stringify(event));
      if (initialConnect) {
        //接続完了した際にQRコードを生成し、表示する
        initialConnect = false;
      }
    };

    //メッセージを待機し、受信したら現在の杯数の変更を切り替える
    socket.onmessage = (event) => {
      console.log("onmessage" + JSON.stringify(event));
      let count = String(event.data);
      console.log("drunk is " + count);
      if (Number(count) >= Number(max_drink_num)) {
        //規定の杯数を超えたら、ダイアログを表示する
        console.log("max drunk");
        setImgSrc(null);
        setOpen(true);
      }
      setText(count);
    };

    //接続が切れたら、再接続を試みる
    socket.onclose = (event) => {
      console.log("onclose" + JSON.stringify(event));
      socket = new WebSocket(socketidUrl);
    };
  }, []);

  return (
    <div className="Eka">
      <Box
        sx={{
          m: 3,
          p: 1,
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color: (theme) =>
            theme.palette.mode === "dark" ? "grey.300" : "grey.800",
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 1,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        {imgSrc && (
          <div css={qr_box}>
            <img src={imgSrc} css={qr_code} alt="QR Code" />
          </div>
        )}

        <h2 css={eka_center}>日本酒ゲット！</h2>
        <p css={eka_center}>
          アンケートにご回答いただき
          <br />
          ありがとうございます。
        </p>
        <p css={eka_center}>
          表示されたQRコードを
          <br />
          「のまっせ」のリーダーにかざして
          <br />
          会津のおいしい地酒をお楽しみください。
        </p>
      </Box>
    </div>
  );
};

const eka_center = css`
  text-align: center;
`;

const qr_box = css`
  margin-top: 2rem;
`;

const qr_code = css`
  width: 13rem;
  display: block;
  margin: auto;
`;

const drunk_st = css`
  font-size: 2.5rem;
`;

const drunk_num = css`
  font-size: 1.5rem;
  text-align: center;
  margin: 0 0 1rem;
`;

const more_drunk_btn = css`
  width: 90%;
  display: block;
  margin: auto;
  text-align: center;
  background-color: #ea4848;
  color: #fff;
`;

const dialogt_style = css`
  color: black;
  text-align: center;
  font-size: 1.3rem;
`;

export default Eka;
