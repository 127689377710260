/** @jsxImportSource @emotion/react  */
import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";

import { css } from "@emotion/react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import logo from "./nomasse-w.svg";

const Home = () => {
  return (
    <div>
      <div css={center}>
        <img src={logo} css={appLogo} alt="logo" />
      </div>
      <Box
        sx={{
          m: 3,
          p: 1,
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 1,
          fontSize: "0.875rem",
          fontWeight: "700",
        }}
      >
        <p css={left}>
          ・未成年者の飲酒は法律で禁じられています
          <br />
          ・飲酒運転は法律で禁止されています
          <br />
          ・妊娠中や授乳期の飲酒は、胎児・乳児の発育に悪影響を与える恐れがあります。
        </p>
      </Box>
      <p css={center}>
        上記内容を理解し、了承します。
        <br />
        また、私は20歳以上です。
      </p>
      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="contained" component={Link} color="primary" to="/form">
          はい
        </Button>
        <Button variant="outlined" color="inherit">
          いいえ
        </Button>
      </Stack>
    </div>
  );
};

const appLogo = css`
  margin: auto;
  height: 130vmin;
  pointer-events: none;
`;

const topmargin = css`
  margin-top: 3.5rem;
`;

const center = css`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: center;
  font-size: 0.85rem;
  font-weight: 700;
`;

const left = css`
  text-align: left;
  margin: 0 1rem 0 1rem;
  font-size: 0.85rem;
`;

export default Home;
