export default class Const {
  static PREF_OPTIONS = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
    "アジア",
    "北米",
    "南米",
    "ヨーロッパ",
    "オセアニア",
    "アフリカ",
  ];

  static PURPOSE_OPTIONS = ["観光", "買い物", "仕事", "その他"];

  static TRAFIC_OPTIONS = [
    "鉄道",
    "バス",
    "自家用車",
    "団体旅行",
    "徒歩",
    "自転車",
  ];

  static PEOPLE_OPTIONS = ["1人", "2人", "3人", "4人以上"];

  static GENDER_OPTIONS = ["男性", "女性", "どちらでもない", "無回答"];

  static GEN_OPTIONS = ["20代", "30代", "40代", "50代", "60代", "70代以上"];

  static QUESTION_LISTS = [
    {
      id: 1,
      type: "select",
      label: "今日はどちらからいらっしゃいましたか？",
      options: Const.PREF_OPTIONS,
      example: "",
      required: true,
    },
    {
      id: 2,
      type: "select",
      label: "どのような目的でいらっしゃいましたか？",
      options: Const.PURPOSE_OPTIONS,
      example: "",
      required: true,
    },
    {
      id: 3,
      type: "select",
      label: "どのような方法でいらっしゃいましたか？",
      options: Const.TRAFIC_OPTIONS,
      example: "",
      required: true,
    },
    {
      id: 4,
      type: "select",
      label: "何人でいらっしゃいましたか？",
      options: Const.PEOPLE_OPTIONS,
      example: "",
      required: true,
    },
    {
      id: 5,
      type: "select",
      label: "性別を教えてください",
      options: Const.GENDER_OPTIONS,
      example: "",
      required: true,
    },
    {
      id: 6,
      type: "select",
      label: "年代を教えてください",
      options: Const.GEN_OPTIONS,
      example: "",
      required: true,
    },
    {
      id: 7,
      type: "select",
      label: "出身地を教えて下さい",
      options: Const.PREF_OPTIONS,
      example: "",
      required: true,
    },
  ];
}
